import objstr from 'obj-str';
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProduct, setUnitOfMeasure } from '@components/ProductDetail/state/productDetailSlice';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { mapProductData, ProductData, VariantOption } from '@utils/mapProductsData';
import * as productConstants from '@utils/productConstants';
import { ScProps } from '@components-react/ScProps';
import { UnitOfMeasure, Variant } from '@sctypes/product/Product';
import { Prices } from '@sctypes/product/Prices';
import { AttributeList } from '@sctypes/product/AttributeList';
import { Attribute } from '@sctypes/product/Attribute';
import { ProductVariantSelectorProps, VariantPhrases } from './ProductVariantSelectorProps';
import styles from './ProductVariantSelector.module.scss';

interface Selection {
  variantCode: string;
  unitCode: string;
}

interface FormattedPrices {
  itemPrice: string;
  exTax: string;
  incTax: string;
}

export const ProductVariantSelector = (props: ScProps<ProductVariantSelectorProps>) => {
  const dispatch = useDispatch();
  const { data } = props;
  const phrases = data.phrases;

  const [product, setProductState]: [ProductData, (value: ProductData) => void] =
    useState(mapProductData(data.product, data.variant));

  useEffect(() => {
    product.unitOfMeasureCode = product.variant?.unitsOfMeasure[0].code;
    dispatch(setProduct(product));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selection, setSelection]: [Selection, (value: Selection) => void] = useState({
    variantCode: product.variant.code,
    unitCode: product.variant.unitsOfMeasure[0].code
  });

  const currentVariant: Variant = product.variant;
  const units: UnitOfMeasure[] = currentVariant.unitsOfMeasure;
  const currentUnit: UnitOfMeasure = units.find((unit: UnitOfMeasure) => unit.code === selection.unitCode);

  const breakpoint: string = useBreakpoint();
  const preferences = useSelector((state: any) => state?.preferences);
  const prices: Prices = currentUnit.prices;
  const { locale = 'nl-NL', displayTax = false } = preferences;
  const variantUrl: string = currentVariant?.url || '';


  useEffect(() => {
    if (variantUrl) {
      try {
        const currentUrl = new URL(window.location.href);
        const currentUrlParams = new URLSearchParams(currentUrl.search);
        const nextVariantUrl = new URL(window.location.href);

        nextVariantUrl.pathname = variantUrl;
        nextVariantUrl.search = currentUrlParams.toString();

        window.history.replaceState(null, '', `${nextVariantUrl.toString()}`);
      } catch (e) {
        // do nothing
      }
    }
  }, [variantUrl]);


  const handleVariantChange = (payload: { optionCode1?: string, optionCode2?: string }) => {
    const optionCode1: string = payload.optionCode1 ?? currentVariant.optionCode1;
    const optionCode2: string = payload.optionCode2 ?? currentVariant.optionCode2;

    let newVariant: Variant = product.variants.find((variant: Variant) => {
      return variant.optionCode1 === optionCode1 && variant.optionCode2 === optionCode2;
    });

    if (!newVariant) {
      newVariant = product.variants.find((variant: Variant) => variant.optionCode1 === optionCode1);

      if (!newVariant) {
        return;
      }
    }

    const newProduct: ProductData = mapProductData(data.product, newVariant);
    const unitWithSameQuantity: UnitOfMeasure = newVariant.unitsOfMeasure
      .filter((uom: UnitOfMeasure) => uom.itemsPerUnit === currentUnit.itemsPerUnit)[0];

    const newUnitOfMeasureCode: string = unitWithSameQuantity?.code ?? newVariant.unitsOfMeasure[0].code;
    newProduct.unitOfMeasureCode = newUnitOfMeasureCode;
    setProductState(newProduct);
    dispatch(setProduct(newProduct));

    setSelection({
      variantCode: newVariant.code,
      unitCode: newUnitOfMeasureCode
    });
  };

  const handleUnitOfMeasureChange = (uomCode: string) => {
    setSelection({
      ...selection,
      unitCode: uomCode
    });

    dispatch(setUnitOfMeasure(uomCode));
  };

  function formatPrices(prices: Prices, itemsPerUnit = 1): FormattedPrices {
    const incTax = prices?.current.incTax.numeric;
    const exTax = prices?.current.exTax.numeric;
    const itemPrice: number = displayTax
      ? (incTax / itemsPerUnit)
      : (exTax / itemsPerUnit);

    return {
      itemPrice: new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(itemPrice),
      exTax: prices.current.exTax.formatted,
      incTax: prices.current.incTax.formatted
    };
  }

  const isSmallScreen: boolean = ['xs', 'sm'].includes(breakpoint);
  const displayPrice: string = preferences.displayTax
    ? prices?.current.incTax.formatted || ''
    : prices?.current.exTax.formatted || '';

  const variantCollapseContainerMinHeight = 130;
  const useRefVariantCollapseContainer = (variantCollapseContainerRef: React.RefObject<HTMLDivElement>) => {
    const [height, setHeight] = useState(0);
    useEffect(() => {
      if (height === 0 && variantCollapseContainerRef.current) {
        const { current } = variantCollapseContainerRef;
        const boundingRect = current.getBoundingClientRect();
        const { /* width,*/ height } = boundingRect;
        setHeight(Math.round(height));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variantCollapseContainerRef]);
    return height;
  };
  const variantCollapseContainer = useRef<HTMLDivElement>();
  const variantCollapseContainerHeight = useRefVariantCollapseContainer(variantCollapseContainer);

  const NonColorVariantOptions = (
    { options, optionCodes, index }: { options: AttributeList, optionCodes: VariantOption[], index: number }
  ) => {
    const optionKey = `optionCode${index + 1}`;
    const optionCode: string = index === 0 ? currentVariant.optionCode1 : currentVariant.optionCode2;

    return (
      <div className="w-100 component">
        <strong className="d-block mb-2">{options.type.value}</strong>
        {isSmallScreen && (
          <select
            className="custom-select custom-select-lg"
            onChange={e => handleVariantChange({ [optionKey]: e.target.value })}
            value={optionCode}
          >
            {options.items.map((option: Attribute) => {
              const { code, value } = option?.value || {};
              const disabled = optionCodes.find((optionCode: VariantOption) => optionCode.value.code === code)?.disabled === true;

              return (
                <option value={code} key={`${code}-${value}`} disabled={disabled}>{value}</option>
              );
            })}
          </select>
        )}
        {!isSmallScreen && (
          <div className={`${styles.variantOptionsSize}`}>
            <div
              ref={variantCollapseContainer}
              // eslint-disable-next-line max-len
              className={`${styles.variantCollapseContainer} ${variantCollapseContainerHeight > variantCollapseContainerMinHeight ? 'collapsable' : 'show'}`}
            >
              {options.items.map((option: Attribute) => {
                const { code, value } = option?.value || {};
                const disabled = optionCodes.find((optionCode: VariantOption) => optionCode.value.code === code)?.disabled;
                const classNames: string = objstr({
                  [styles.option]: true,
                  [styles.optionLabel]: true,
                  [styles.optionLabelActive]: (code === optionCode),
                  [styles.optionLabelDisabled]: disabled,
                  transition: true
                });
                return (
                  <span
                    className={classNames}
                    onClick={() => !disabled && handleVariantChange({ [optionKey]: code })}
                    key={`${code}-${value}`}
                  >
                    {value}
                  </span>
                );
              })}
            </div>
            <div className={`${styles.variantCollapseButtonContainer} text-center w-100`}>
              {/* eslint-disable-next-line max-len */}
              <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => variantCollapseContainer.current.classList.toggle('show')}>
                <span className="showMore">{phrases.showMore}</span>
                <span className="showLess">{phrases.showLess}</span>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  let currentUnitDescription: string = currentUnit.description;
  if (currentUnit.itemsPerUnit > 1) {
    const { itemPrice }: FormattedPrices = formatPrices(currentUnit.prices, currentUnit.itemsPerUnit);
    currentUnitDescription = currentUnitDescription.replace('#PRICE#', itemPrice);
  }

  return (
    <>
      <div className="w-100 component">
        <div className={styles.pricelabel}>
          {displayPrice && (<>
            <span className={styles.pricelabelPrice}>
              {displayPrice}
            </span>
            <span className={styles.pricelabelUnitOfMeasure}>{currentUnitDescription}</span>
          </>
          )}
        </div>
        <span className="text-muted text-sm">{displayTax ? 'Incl. BTW' : 'Excl. BTW'}</span>
      </div>
      {product.variantOptions.map((options: AttributeList, idx: number) => {
        let optionCodes: VariantOption[];
        if (idx === 0) {
          optionCodes = product.availableOptionCodes1;
        } else {
          optionCodes = product.availableOptionCodes2;
        }

        if (options.type.code === productConstants.VARIANT_TYPE_COLOR) {
          return <ColorVariantOptions
            key={idx}
            phrases={phrases}
            currentVariant={currentVariant}
            handleVariantChange={handleVariantChange}
            index={idx}
            options={options.items} />;
        }

        return <NonColorVariantOptions
          key={idx}
          index={idx}
          options={options}
          optionCodes={optionCodes} />;
      })}
      <div className="w-100 component">
        <strong className="d-block mb-2">{phrases.unitLabel}</strong>
        {isSmallScreen && (
          <select
            className="custom-select custom-select-lg"
            onChange={e => handleUnitOfMeasureChange(e.target.value)}
            value={currentUnit.code}
          >
            {units.map((unit: UnitOfMeasure) => {
              const { code, name, prices }: UnitOfMeasure = unit;
              const { itemPrice }: FormattedPrices = formatPrices(prices, 1);
              const className: any = unit.code === currentUnit.code ? [styles.unitOfMeasureActive] : '';
              return (
                <option
                  className={className}
                  value={code}
                  key={code}
                >
                  {name} {itemPrice}
                </option>
              );
            })}
          </select>
        )}
        {!isSmallScreen && (
          <div className={styles.allUnitsOfMeasure}>
            {units?.length && units.map((unit: UnitOfMeasure, idx) => {
              const { code, name, prices }: UnitOfMeasure = unit;
              const { itemPrice }: FormattedPrices = formatPrices(prices, 1);
              const isSelected: boolean = unit.code === currentUnit.code;
              const classNames: string = objstr({
                [styles.option]: true,
                [styles.optionLabel]: true,
                [styles.unitOfMeasure]: true,
                [styles.unitOfMeasureActive]: (isSelected && units.length !== 1),
                [styles.unitOfMeasureSingle]: units.length === 1,
                transition: true
              });
              return (
                <span
                  className={classNames}
                  onClick={() => handleUnitOfMeasureChange(code)}
                  key={`${idx}-${code}`}
                >
                  <strong>{name}</strong><br />
                  <span className="text-muted text-sm">{itemPrice}</span>
                </span>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

const ColorVariantOptions = ({ handleVariantChange, currentVariant, phrases, options, index }:
  {
    handleVariantChange: (value: { optionCode1?: string, optionCode2?: string }) => void,
    currentVariant: Variant,
    phrases: VariantPhrases,
    options: Attribute[],
    index: number
  }) => {
  const optionKey = `optionCode${index + 1}`;
  const optionCode = index === 0 ? currentVariant.optionCode1 : currentVariant.optionCode2;

  return (
    <div className="mb-3">
      <strong className="d-block mb-2">{phrases.colorLabel}</strong>
      <div className={styles.variantOptions}>
        {options.map((option: Attribute, idx: number) => {
          const { code, value } = option?.value || {};
          const classNames: string = objstr({
            [styles.option]: true,
            [styles.optionColor]: true,
            [styles.optionColorActive]: (code === optionCode),
            transition: true,
            tooltip: true
          });
          const image: string = option.images[0]?.src;
          if (!image) {
            return null;
          }

          return (
            <span
              className={classNames}
              onClick={() => handleVariantChange({ [optionKey]: code })}
              key={`${idx}-${code}`}
            >
              <img
                src={image}
                alt={value}
                className={styles.colorImage}
              />
              <span className="tooltip_text">{value}</span>
            </span>
          );
        }
        )}
      </div>
    </div>
  );
};
