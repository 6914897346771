import { createAction } from '@reduxjs/toolkit';
import type * as Types from './types';
import type * as ProductTypes from '@/types/product/Product';

export interface IOnsiteSearch {
  searchTerm: string;
  searchResultCount: number;
}
export const onsiteSearch = createAction(
  'gmt/onsiteSearch',
  function prepare({ searchTerm, searchResultCount }: IOnsiteSearch) {
    return {
      payload: {
        event: 'onsite_search',
        search_term: searchTerm,
        search_results: searchResultCount
      }
    };
  });


interface ICreateAddToCartAction {
  cartItems: [{
    orderAmount: number;
    variantCode: string;
    unitOfMeasureCode: string;
    currentVariant: ProductTypes.Variant;
  }]
}
export const createAddToCartAction = createAction('gmt/add_to_cart', function prepare({ cartItems }: ICreateAddToCartAction ) {
  const subTotal = cartItems?.reduce((accumulator: number, item) => {
    const unitOfMeasure = item.currentVariant.unitsOfMeasure.find(uom => uom.code === item.unitOfMeasureCode);
    return accumulator + (unitOfMeasure.prices.current.exTax.numeric * item.orderAmount);
  }, 0);


  const items = cartItems.map((item: any) => {
    const unitOfMeasure = item.currentVariant.unitsOfMeasure.find((uom: ProductTypes.UnitOfMeasure) => uom.code === item.unitOfMeasureCode);

    return {
      item_id: item.currentVariant.code ?? item.varaintCode ?? item.code,
      item_brand: '',
      item_name: item.currentVariant.title ?? item.variantTitle ?? item.title,
      item_category: item.category,
      item_category2: '',
      item_category3: '',
      price: unitOfMeasure?.prices.current.exTax.numeric,
      quantity: item.orderAmount
    };
  });

  const gtmViewCartEvent: Types.GtmViewCartEvent = {
    event: 'add_to_cart',
    ecommerce: {
      value: subTotal,
      currency: 'EUR',
      items
    }
  };

  return {
    payload: {
      ...gtmViewCartEvent
    }
  };
});
