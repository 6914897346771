import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidV4 } from 'uuid';
import api from '@utils/api';
import { createAddToCartAction } from '@/features/gmt/gmtActions';

export const getCountThunk = createAsyncThunk(
  'cart/getcount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('cart/getcount');
      if (window && window.appInsights) {
        window.appInsights.trackTrace({
          message: 'cart/getcount',
          properties: {
            ...response.data
          }
        });
      }

      return response.data;
    } catch (error) {
      const serializedError = JSON.stringify(error, Object.getOwnPropertyNames(error));
      return rejectWithValue(serializedError);
    }
  }
);

export const addItemsThunk = createAsyncThunk(
  'cart/addItems',
  async (items, { dispatch, rejectWithValue }) => {
    const requestBody = {
      lines: items.map(item => ({
        variantCode: item.variantCode,
        // Strip the variantcode + semicolon
        unitOfMeasureCode: item.unitOfMeasureCode.replace(`${item.variantCode}:`, ''),
        quantity: item.orderAmount
      }))
    };
    try {
      const response = await api.post('cart/addItems', requestBody);
      dispatch(cartSlice.actions.addToCart({ items }));
      return response.data;

    } catch (error) {
      const serializedError = JSON.stringify(error, Object.getOwnPropertyNames(error));
      return rejectWithValue(serializedError);
    }
  }
);


export const deleteItemThunk = createAsyncThunk(
  'cart/deleteItem',
  async (variantCode, { rejectWithValue }) => {
    const requestBody = {
      variantCode: variantCode
    };
    try {
      const response = await api.post('cart/deleteItem', requestBody);
      return response.data;

    } catch (error) {
      const serializedError = JSON.stringify(error, Object.getOwnPropertyNames(error));
      return rejectWithValue(serializedError);
    }
  }
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    loading: 'idle',
    totalPieces: 0,
    noOfLines: 0
  },
  reducers: {
    addToCart: (state, { payload }) => {
      payload.items.forEach((item) => {
        const { orderAmount, variantCode, unitOfMeasureCode } = item;
        const newItem = {
          id: uuidV4(),
          orderAmount: parseInt(orderAmount, 10),
          variantCode,
          unitOfMeasureCode
        };

        state.items.push(newItem);
      });
      return state;
    },

    removeFromCartById: (state, { payload }) => {
      const { id } = payload;
      return state.items.filter(item => item.id !== id);
    },

    removeFromCartByVariantUom: (state, { payload }) => {
      const { variantCode, unitOfMeasureCode } = payload;

      const itemToRemove = state.items.find(
        item => item.variantCode === variantCode && item.unitOfMeasureCode === unitOfMeasureCode
      );

      // TODO: Call API endpoint to remove items
      return {
        ...state,
        items: itemToRemove ? state.items.filter(item => item.id !== itemToRemove.id) : state.items
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addItemsThunk.fulfilled, (state, action) => {

      // old API returns a 'data' key, the new one does not. We need to handle both cases
      const { lines = [], totalPieces, noOfLines } = action.payload.data ?? action.payload;

      const items = lines.map(item => ({
        orderAmount: item.quantity,
        variantCode: item.itemNo,
        unitOfMeasureCode: item.unit
      }));

      return {
        ...state,
        items,
        totalPieces,
        noOfLines
      };
    });

    builder.addCase(addItemsThunk.rejected, (state /*, action */) => {
      // TODO: Some sort of UI feedback that an error occured
      return {
        ...state,
        loading: 'idle'
      };
    });


    builder.addCase(getCountThunk.fulfilled, (state, action) => {
      const { totalPieces = 0, noOfLines = 0, ...rest } = action.payload;

      return {
        ...state,
        totalPieces,
        noOfLines,
        ...rest
      };
    });

    builder.addCase(getCountThunk.rejected, (state /*, action */) => {
      // TODO: Some sort of UI feedback that an error occured
      return {
        ...state,
        loading: 'idle'
      };
    });


    builder.addCase(deleteItemThunk.fulfilled, (state, action) => {
      const { items } = action.payload;
      return {
        ...state,
        items
      };
    });

    builder.addCase(deleteItemThunk.rejected, (state /*, action */) => {
      // TODO: Some sort of UI feedback that an error occured
      return {
        ...state,
        loading: 'idle'
      };
    });
  }
});

export const selectCart = state => state.cart;
export const { removeFromCartById } = cartSlice.actions;


export function removeFromCartByVariantUom(dispatch){
  return async function ({ unitOfMeasureCode, variantCode }) {
    dispatch(cartSlice.actions.removeFromCartByVariantUom({
      unitOfMeasureCode, variantCode
    }));

    dispatch(deleteItemThunk({ unitOfMeasureCode, variantCode }));
  };
}

export function getCart(dispatch) {
  return async function () {
    dispatch(getCountThunk());
  };
}

export function addToCart(dispatch) {
  return async function (items) {
    dispatch(cartSlice.actions.addToCart({ items }));
    dispatch(addItemsThunk(items));
    dispatch(createAddToCartAction({ cartItems: items }));
  };
}
