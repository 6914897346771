/**
 * TODO: Only import / export components that are content managable
 */

import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

import { AddToOrderLists } from '@components-react/OrderLists/AddToOrderListsButton/AddToOrderListsButton';
import { BackToOverview } from '@components-react/BackToOverview/BackToOverview';
import { BrandsList } from '@components-react/BrandsList/BrandsList';
import { Breadcrumb } from '@components-react/Breadcrumb/Breadcrumb';
import { CTACarousel } from '@components-react/CTACarousel/CTACarousel';
import { CallToAction } from '@components-react/CallToAction/CallToAction';
import { CallToActionLargeVariants } from '@components-react/CallToActionLargeVariants/CallToActionLargeVariants';
import { CallToActionSmall } from '@components-react/CallToActionSmall/CallToActionSmall';
import { CustomerRating } from '@components-react/CustomerRating/CustomerRating';
import { CustomerRatingsCarousel } from '@components-react/CustomerRatingsCarousel/CustomerRatings';
import { ErrorFeedback } from '@components-react/ErrorFeedback/ErrorFeedback';
import { ErrorTest } from '@components-react/ErrorTest/ErrorTest';
import { ExampleTypeScript } from '@components-react/TypeScriptExample/ExampleTypeScript';
import { HeroBanner } from '@components-react/HeroBanner/HeroBanner';
import { HorizontalLine } from '@components-react/HorizontalLine/HorizontalLine';
import { ProductDetailAddToCart } from '@components-react/ProductDetail/ProductDetailAddToCart/ProductDetailAddToCart';
import { ProductSpecialist } from '@components-react/ProductSpecialist/ProductSpecialist';
import { ProductSpecialistWide } from '@components-react/ProductSpecialist/ProductSpecialistWide';
import { ProductTitle } from '@components-react/ProductDetail/ProductTitle/ProductTitle';
import { ProvideAddToCartLayer } from '@components-react/Cart/AddToCartLayer/ProvideAddToCartLayer';
import { ProvideCategoryFacets } from '@components-react/ProductOverview/Providers/ProvideCategoryFacets';
import { ProvideFilterBadges } from '@components-react/ProductOverview/Providers/ProvideFilterBadges';
import { ProvideOpenFacetsButton } from '@components-react/ProductOverview/Providers/ProvideOpenFacetsButton';
import { ProvidePagination } from '@components-react/ProductOverview/Providers/ProvidePagination';
import { ProvideProductCount } from '@components-react/ProductOverview/Providers/ProvideProductCount';
import { ProvideSortOrder } from '@components-react/ProductOverview/Providers/ProvideSortOrder';
import { ProvideProductTileList } from '@components-react/ProductTile/ProvideProductTileList';
import { ProvideProductVariantSelector } from '@components-react/ProductDetail/ProductVariantSelector/ProvideProductVariantSelector';
import { ProvideSearchBox } from '@components-react/Search/Providers/ProvideSearchBox';
import { ProvideShoppingCartButton } from '@components-react/Cart/ShoppingCartButton/ProvideShoppingCartButton';
import { ProvideToastContainer } from '@components-react/Toast/ProvideToastContainer';
import { QuotationRequest } from '@components-react/ProductDetail/QuotationRequest/QuotationRequest';
import { USP } from '@components-react/USP/USP';
import { withScProps } from '@components-react/WithScProps/WithScProps';

// Needs to be initialized before other components are mounted, so emitted toasts are handled
if (typeof document !== 'undefined') {
  document.addEventListener('DOMContentLoaded', () => {
    const portalNode = document.querySelector('#portal') || null;
    if (portalNode) {
      /* eslint-disable react/no-deprecated */
      // We are using React 17.*
      ReactDOM.render(ReactDOM.createPortal(<ProvideToastContainer />, portalNode), document.createElement('div'));
      ReactDOM.render(ReactDOM.createPortal(<ProvideAddToCartLayer />, portalNode), document.createElement('div'));
      /* eslint-enable */
    }
  });
}

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;
global.Components = {
  AddToOrderLists,
  BackToOverview,
  BrandsList,
  Breadcrumb,
  CTACarousel,
  CallToAction,
  CallToActionLargeVariants,
  CallToActionSmall,
  CustomerRating,
  CustomerRatingsCarousel,
  ErrorFeedback,
  ErrorTest,
  ExampleTypeScript,
  HeroBanner,
  HorizontalLine,
  ProductDetailAddToCart,
  ProductSpecialist,
  ProductSpecialistWide,
  ProductTitle,
  ProvideAddToCartLayer,
  ProvideCategoryFacets: withScProps(ProvideCategoryFacets),
  ProvideFilterBadges: withScProps(ProvideFilterBadges),
  ProvideOpenFacetsButton: withScProps(ProvideOpenFacetsButton),
  ProvidePagination: withScProps(ProvidePagination),
  ProvideProductCount: withScProps(ProvideProductCount),
  ProvideProductTileList: withScProps(ProvideProductTileList),
  ProvideProductVariantSelector,
  ProvideSearchBox: withScProps(ProvideSearchBox),
  ProvideShoppingCartButton: withScProps(ProvideShoppingCartButton),
  ProvideSortOrder: withScProps(ProvideSortOrder),
  QuotationRequest,
  USP
};
